import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "../../../../styles/module/seachDatabase.module.css";

export default function SearchHome() {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const selectOpen = (e) => {
    // e.preventDefault();
    navigate(`/pesquisar/${search}/`);
    window.location.reload(true);
  };
  const handleSubmit = (e) => {
    selectOpen();
    // e.preventDefault();
  };

  return (
    <>
      <div className={styled.searchPageCenter}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="from-portarias">
            <input
              type="text"
              id="search"
              className="form__input"
              autoComplete="off"
              placeholder=" "
              onChange={(e) => setSearch(e.target.value)}
            ></input>
            <label htmlFor="search" className="form-label-portarias">
              Buscar
            </label>
            <div className="img-search-portarias">
              <img
                src="/icons/search.svg"
                alt="icone-download"
                onClick={(e) => selectOpen(e)}
                className="icone-buscar-portarias"
              ></img>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
